<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          {{ $t("labels.add_license_price") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="item.name"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.function')"
              :placeholder="$t('labels.function')"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-textarea
              v-model="item.note"
              class="c-input-small"
              rows="3"
              outlined
              dense
              hide-details
              :label="$t('labels.note')"
              :placeholder="$t('labels.note')"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.id_country"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.country')"
              :placeholder="$t('labels.country')"
              :items="countries"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.classify"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.classify')"
              :placeholder="$t('labels.classify')"
              :items="classifyOptions"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.type"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.administer')"
              :placeholder="$t('labels.administer')"
              :items="typeOptions"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.unit"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.unit')"
              :placeholder="$t('labels.unit')"
              :items="unitOptions"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <input-number
              v-model="item.max_order_per_month"
              outlined
              dense
              hide-details
              class="c-input-small"
              :label="$t('labels.max_order_per_month')"
              :placeholder="$t('labels.max_order_per_month')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <input-number
              v-model="item.max_account_per_warehouse"
              outlined
              dense
              hide-details
              class="c-input-small"
              :label="$t('labels.max_account_per_warehouse')"
              :placeholder="$t('labels.max_account_per_warehouse')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <input-number
              v-model="item.price"
              outlined
              dense
              hide-details
              class="c-input-small"
              :label="$t('labels.price')"
              :placeholder="$t('labels.price')"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.vat"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.vat')"
              :placeholder="$t('labels.vat')"
              :items="vatOptions"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="item.status"
              class="c-input-small"
              outlined
              dense
              hide-details
              :label="$t('labels.status')"
              :placeholder="$t('labels.status')"
              :items="statusOptions"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm">
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  LICENSE_PRICE_STATUS_OPTIONS,
  LICENSE_PRICE_UNIT_OPTIONS,
  LICENSE_PRICE_TYPE_OPTIONS,
  LICENSE_PRICE_CLASSIFY_OPTIONS,
  VAT_OPTIONS,
} from "@/libs/const";
export default {
  components: {},
  name: "PriceCreate",
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    item: {
      name: null,
      note: null,
      id_country: null,
      classify: null,
      type: null,
      unit: null,
      max_order_per_month: null,
      max_account_per_warehouse: null,
      price: null,
      vat: null,
      status: 1,
    },
    statusOptions: [...LICENSE_PRICE_STATUS_OPTIONS],
    typeOptions: [...LICENSE_PRICE_TYPE_OPTIONS],
    classifyOptions: [...LICENSE_PRICE_CLASSIFY_OPTIONS],
    unitOptions: [...LICENSE_PRICE_UNIT_OPTIONS],
    vatOptions: [...VAT_OPTIONS],
    countries: [],
  }),
  created() {
    if (this.itemDetail && this.itemDetail.id) {
      this.item = { ...this.itemDetail };
    }
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      const { data } = await httpClient.post("/common/v1/get-country");
      this.countries = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    cancel() {
      this.$emit("cancel");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/license/v1/save", this.item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshData();
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
